<template >
  <section>
    <v-chip-group mandatory show-arrows active-class="primary--text" v-model="selectedCategory" @click="getDoctorsById">
      <v-chip v-for="category in categories" :key="category.id">
        {{ category.value }}
      </v-chip>
    </v-chip-group>

    <div v-if="items && items.length > 0">
      <v-card 
        class="my-3 pa-3 d-flex flex-row justify-space-between align-center border rounded-lg" 
        flat 
        v-for="(item, i) in items" 
        :key="i" 
        :to="`/c/specialist/${item.id}`">
        <div class="d-flex flex-row align-center">
          <div>
            <v-avatar size="72">
              <v-img :lazy-src="require('@/assets/images/profile-circle.png')" v-if="item.pic" :src="item.pic" :alt="item.firstname+' '+item.lastname" />
              <v-img v-else :src="require('@/assets/images/profile.jpg')" />
            </v-avatar>
          </div>
          <div class="ms-3 me-0">
            <span class="d-block f15 font-weight-bold">{{ item.firstname }} {{ item.lastname }}</span>
            <span class="f13" v-if="item.specialties && item.specialties.length">{{
                item.specialties[0].value
            }}</span>
            <span class="f13 primary--text d-block">{{
                item.clinic.name
            }}</span>
          </div>
        </div>
        <v-btn class="mx-2 rounded-lg" elevation="0" v-if="!item.isFollowed" @click.prevent="follow(item)">{{$t("Follow")}}</v-btn>
        <v-btn class="mx-2 rounded-lg" elevation="0" color="primary" v-else-if="item.isFollowed" @click.prevent="unfollow(item)">{{$t("Followed")}}</v-btn>
      </v-card>
    </div>

    <span class="text-center f16 d-block mt-8" v-else>
      {{$t('No results found')}}
    </span>

  </section>
</template>
<script>
import apiService from "@/http/ApiService";
export default {
  data() {
    return {
      categories: [],
      selectedCategory: 0,
      items: [],
      page: 1,
      size: 12,
    };
  },
  methods: {
    getAllCategories() {
      apiService.getActivities()
        .then((response) => {
          this.categories = response.data;
          var first = {
            id: -1,
            value: "همه",
          };

          this.categories.unshift(first);
          this.getDoctorsById(0, this.size);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDoctorsById(page, size) {
      apiService.getDoctorsById(
        this.categories[this.selectedCategory].id,
        page,
        size
      )
        .then((response) => {
          this.items = response.data.content;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    follow(doctor){
        apiService.setDoctorFollow(doctor.id, this.$store.getters.clientId)
        .then(() => {
          this.items.find(data => data.id === doctor.id).isFollowed = true;
          // this.doctor.isFollowed = true;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
        });
    },
    unfollow(doctor){
        this.$swal({
          icon: "warning",
          showDenyButton: true,
          text: this.$t("Are you sure you want to unfollow the specialist?"),
          confirmButtonText: this.$t('Yes'),
          denyButtonText: this.$t('No'),
        })
        .then((result) => {
          if (result.isConfirmed) {
            apiService.setDoctorUnFollow(doctor.id, this.$store.getters.clientId)
            .then(() => {
              this.items.find(data => data.id === doctor.id).isFollowed = false;
              // this.doctor.isFollowed = false;
            })
            .catch((err) => {
              this.$swal({
                icon: "warning",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t('OK'),
              })
            });
          }
        });
    },
  },
  watch: {
    selectedCategory: function () {
      this.page = 1;
      this.getDoctorsById(0, this.size);
      
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    this.getAllCategories();

  },
};
</script>